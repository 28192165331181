import React from 'react';

const UserOrders = () => {
  return (
    <div>
      <h1>Orders Placeholder</h1>
    </div>
  );
};

export default UserOrders;
