import * as React from 'react';
import Typography from '@mui/material/Typography';

const TransferFees = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        All FFL Dealers in our network have agreed to not charge standard
        transfer fees on firearms ordered through our website. Some states and
        localaties do require fees to be collected. These fees are not
        considered standard transfer fees. If your FFL Dealer attempts to charge
        you for a standard transfer fee when you arrive at their store, please
        contact us and let us know before you complete the purchase.
      </Typography>
    </>
  );
};
export default TransferFees;
