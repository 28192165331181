import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AxiosGet from '../appUtilities/axiosGet';

const Dealers = () => {
  const [dealers, setDealers] = useState([]);

  useEffect(() => {
    AxiosGet('/dealers').then((res) => {
      setDealers(res.data);
    });
  }, []);

  return (
    <Grid container>
      {dealers.map((dealer) => (
        <Grid item xs={4}>
          <div key={dealer.contact_id}>
            <h4>{dealer.name}</h4>
            <p>{dealer.address_1}</p>
            <p>{dealer.city}</p>
            <p>{dealer.state}</p>
            <p>{dealer.zip_code}</p>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default Dealers;
