import { Menu, MenuItem, Grid, Divider, Typography, Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCart,
  decrementItemQuantity,
  incrementItemQuantity,
} from '../../redux/shoppingCartSlice';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import GeneralButton from '../../appComponents/generalButton';

const MiniCart = ({ anchorEl, handleClose, open }) => {
  const cart = useSelector((state) => state.persistedReducer.shoppingCart.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateTotalPrice = () => {
    const total = cart.map((item) => item.price * item.quantity).reduce((a, b) => a + b, 0);
    return total;
  };

  const calculateTotalQuantity = () => {
    const totalQuantity = cart.map((item) => item.quantity).reduce((a, b) => a + b, 0);
    return totalQuantity;
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'center' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: 300,
            backgroundColor: 'white',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.8,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <Grid container>
          {cart.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Grid container>
                <Grid item xs={3}>
                  <img src={item.image} alt={item.name} style={{ width: '80%' }} />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant='body'
                    sx={{
                      fontFamily: "'Slabo, 27px', serif",
                      color: '#282c34',
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant='body'
                    sx={{
                      fontFamily: "'Slabo, 27px', serif",
                      color: '#282c34',
                    }}
                  >
                    ${item.price}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <GeneralButton
                    size='small'
                    sx={{
                      backgroundColor: 'white',
                      color: '#FE4F13',
                      borderRadius: 1,
                      maxWidth: '30px',
                      maxHeight: '30px',
                      minWidth: '30px',
                      minHeight: '30px',
                      fontWeight: 700,
                      ':hover': { backgroundColor: 'white' },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(decrementItemQuantity(item.id));
                    }}
                  >
                    <RemoveIcon fontSize='inherit' />
                  </GeneralButton>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant='body'
                    sx={{
                      fontFamily: "'Slabo, 27px', serif",
                      color: '#282c34',
                    }}
                  >
                    {item.quantity}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Button
                    size='small'
                    sx={{
                      backgroundColor: 'white',
                      color: '#FE4F13',
                      maxWidth: '30px',
                      maxHeight: '30px',
                      minWidth: '30px',
                      minHeight: '30px',
                      borderRadius: 1,
                      fontWeight: 700,
                      ':hover': { backgroundColor: 'white' },
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(incrementItemQuantity(item.id));
                    }}
                  >
                    <AddIcon fontSize='inherit' />
                  </Button>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
        <MenuItem>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            <Typography
              variant='body'
              sx={{
                fontFamily: "'Slabo, 27px', serif",
                color: '#282c34',
              }}
            >
              Total: ${calculateTotalPrice()}
            </Typography>
          </Grid>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Grid item xs>
            <Typography
              variant='body'
              sx={{
                fontFamily: "'Slabo, 27px', serif",
                color: '#282c34',
              }}
            >
              Quantity: {calculateTotalQuantity()}
            </Typography>
          </Grid>
        </MenuItem>
        <Divider sx={{ backgroundColor: 'black' }} variant='fullWidth' />
        <Grid container>
          <Grid item xs={5} container justifyContent='space-evenly'>
            <GeneralButton text='Empty Cart' onClick={() => dispatch(clearCart())} />
          </Grid>
          <Grid item xs={2} container justifyContent='space-evenly' />
          <Grid item xs={5} container justifyContent='space-evenly'>
            <GeneralButton text='Checkout' onClick={() => navigate('/checkout')} />
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default MiniCart;
