import { Route, Routes } from 'react-router-dom';
import OutdoorPrizes from '../OutdoorPrizes/OutdoorPrizes';

const OutdoorPrizesRoutes = () => {
  //https://www.outdoorprizes.com/CS2N9X56TY-1AIGRDKZQ4EV-WHWFA9L02S-KALSKLSG5N2

  return (
    <Routes>
      <Route path='/*' element={<OutdoorPrizes />} />
    </Routes>
  );
};

export default OutdoorPrizesRoutes;
