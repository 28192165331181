import React from 'react';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const MobileNavbar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' sx={{ backgroundColor: '#282c34' }}>
        <Toolbar>
          <IconButton size='large' edge='start' color='inherit' aria-label='menu' />
          <MenuIcon />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MobileNavbar;
