import { Select, Checkbox, OutlinedInput, MenuItem, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const FilterBox = (detail) => {
  const searchResults = useSelector((state) => state.persistedReducer.searchResult.value);

  const [filterList, setFilterList] = useState([]);

  const handleFilterChange = (e) => {
    setFilterList(e.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 240,
        background: 'white',
      },
    },
  };

  return (
    <>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        value={filterList}
        onChange={handleFilterChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Brand</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE4F13',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '.MuiSvgIcon-root ': {
            fill: 'white !important',
          },
          width: 240,
        }}
      >
        <MenuItem disabled value=''>
          <em>Brand</em>
        </MenuItem>
        {searchResults.hits.hit.map((item) => (
          <MenuItem key={item.fields.brand} value={item.fields.brand}>
            <Checkbox checked={filterList.indexOf(item.fields.brand) > -1} />
            <ListItemText primary={item.fields.brand} />
          </MenuItem>
        ))}
      </Select>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        value={filterList}
        onChange={handleFilterChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Model</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE4F13',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '.MuiSvgIcon-root ': {
            fill: 'white !important',
          },
          width: 240,
        }}
      >
        <MenuItem disabled value=''>
          <em>Model</em>
        </MenuItem>
        {searchResults.hits.hit.map((item) => (
          <MenuItem key={item.fields.model} value={item.fields.model}>
            <Checkbox checked={filterList.indexOf(item.fields.model) > -1} />
            <ListItemText primary={item.fields.model} />
          </MenuItem>
        ))}
      </Select>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        value={filterList}
        onChange={handleFilterChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Material</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE4F13',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '.MuiSvgIcon-root ': {
            fill: 'white !important',
          },
          width: 240,
        }}
      >
        <MenuItem disabled value=''>
          <em>Material</em>
        </MenuItem>
        {searchResults.hits.hit.map((item) => (
          <MenuItem key={item.fields.material_facet} value={item.fields.material_facet}>
            <Checkbox checked={filterList.indexOf(item.fields.material_facet) > -1} />
            <ListItemText primary={item.fields.material_facet} />
          </MenuItem>
        ))}
      </Select>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        displayEmpty
        value={filterList}
        onChange={handleFilterChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Caliber</em>;
          }

          return selected.join(', ');
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE4F13',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '.MuiSvgIcon-root ': {
            fill: 'white !important',
          },
          width: 240,
        }}
      >
        <MenuItem disabled value=''>
          <em>Caliber</em>
        </MenuItem>
        {searchResults.hits.hit.map((item) => (
          <MenuItem key={item.fields.caliber_facet} value={item.fields.caliber_facet}>
            <Checkbox checked={filterList.indexOf(item.fields.caliber_facet) > -1} />
            <ListItemText primary={item.fields.caliber_facet} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default FilterBox;
