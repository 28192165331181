import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import searchResultSlice from './searchResultSlice';
import shoppingCartSlice from './shoppingCartSlice';
import itemDetailSlice from './itemDetailSlice';
import userInformationSlice from './userInformationSlice';
import categorySlice from './categorySlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  searchResult: searchResultSlice,
  shoppingCart: shoppingCartSlice,
  itemDetail: itemDetailSlice,
  userInformation: userInformationSlice,
  categories: categorySlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: {
    persistedReducer,
  },
});
