import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import GeneralButton from '../appComponents/generalButton';
import AxiosGet from '../appUtilities/axiosGet';

const UserSettings = () => {
  const user = useSelector((state) => state.persistedReducer.userInformation.value);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    emailAddress: '',
    dateOfBirth: '',
    username: '',
    password: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    axios
      .put(`/users/${user.profileId}/update`, {
        email_address: values.emailAddress,
        first_name: values.firstName,
        last_name: values.lastName,
        address_1: values.address1,
        city: values.city,
        state: values.state,
        postal_code: values.zipCode,
      })
      .then((response) => {
        console.log(response);
      });

    event.preventDefault();
  };

  useEffect(() => {
    AxiosGet(`/users/${user.userId}`).then((response) => {
      setValues({
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        address1: response.data.address_1,
        address2: response.data.address_2,
        city: response.data.city,
        state: response.data.state,
        zipCode: response.data.postal_code,
        emailAddress: response.data.email_address,
        dateOfBirth: response.data.birthdate,
        username: response.data.user_name,
        password: response.data.password,
      });
    });
  }, [user.userId]);

  return (
    <form onSubmit={handleSubmit} style={{ padding: '1rem' }}>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={6}>
          <TextField
            name='firstName'
            label='First Name'
            value={values.firstName}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='lastName'
            label='Last Name'
            value={values.lastName}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='address1'
            label='Address 1'
            value={values.address1}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='address2'
            label='Address 2'
            value={values.address2}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='city'
            label='City'
            value={values.city}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='state'
            label='State'
            value={values.state}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='zipCode'
            label='Zip Code'
            value={values.zipCode}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='email'
            label='Email Address'
            value={values.emailAddress}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='dateOfBirth'
            label='Date of Birth'
            value={values.dateOfBirth}
            onChange={handleChange}
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name='username'
            label='Username'
            value={values.username}
            onChange={handleChange}
            disabled
            fullWidth
            variant='outlined'
            margin='normal'
          />
        </Grid>
        <Grid item xs={6}>
          <GeneralButton text='Change Password' />
        </Grid>
        <Grid item xs={6}>
          <GeneralButton text='Save' onClick={handleSubmit} />
        </Grid>
      </Grid>
    </form>
  );
};

export default UserSettings;
