import React from 'react';

const StoreSelect = () => {
  return (
    <>
      <h1>Store Select Placeholder</h1>
      <h4>Using this for React Router Setup</h4>
    </>
  );
};

export default StoreSelect;
