import { createSlice } from '@reduxjs/toolkit';

export const userInformationSlice = createSlice({
  name: 'userInformation',
  initialState: {
    value: [],
  },
  reducers: {
    setUserInformation: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setUserInformation } = userInformationSlice.actions;
export default userInformationSlice.reducer;
