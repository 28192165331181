import { Grid } from '@mui/material';
import React from 'react';
import GeneralButton from '../../appComponents/generalButton';

const HomeWelcome = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Welcome</h1>
        <GeneralButton text='Test' />
      </Grid>
    </Grid>
  );
};

export default HomeWelcome;
