import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';

const Venue = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    axios
      .get('/rotr/venue/content')
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (content) {
      content.forEach((entry) => {
        const element = document.getElementById(entry.content_code);
        if (element) {
          element.innerHTML = entry.content;
        }
      });
    }
  }, [content])

  return (
    <Grid container justifyContent='center'>
      <Grid item container xs={12} md={12} id='VENUE_ROW1_COL1' />
      <Grid item container xs={12} md={6} id='VENUE_ROW2_COL1' />
      <Grid item container xs={12} md={6} id='VENUE_ROW2_COL2' />
      <Grid item container xs={12} md={4} id='VENUE_ROW3_COL1' />
      <Grid item container xs={12} md={4} id='VENUE_ROW3_COL2' />
      <Grid item container xs={12} md={4} id='VENUE_ROW3_COL3' />
      <Grid item container xs={12} md={3} id='VENUE_ROW4_COL1' />
      <Grid item container xs={12} md={3} id='VENUE_ROW4_COL2' />
      <Grid item container xs={12} md={3} id='VENUE_ROW4_COL3' />
      <Grid item container xs={12} md={3} id='VENUE_ROW4_COL4' />
      <Grid item container xs={12} md={12} id='VENUE_ROW5_COL1' />
    </Grid>
  );
};

export default Venue;
