import { Grid } from '@mui/material';
import React from 'react';
import HomeBanners from './components/homeBanners';
import HomeCarousel from './components/homeCarousel';
import HomeContent from './components/homeContent';
import HomeFeatured from './components/homeFeatured';
import HomeWelcome from './components/homeWelcome';

const Home = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <HomeWelcome />
      </Grid>
      <Grid item xs={12}>
        <HomeCarousel />
      </Grid>
      <Grid item xs={12}>
        <HomeContent />
      </Grid>
      <Grid item xs={12}>
        <HomeBanners />
      </Grid>
      <Grid item xs={12}>
        <HomeFeatured />
      </Grid>
    </Grid>
  );
};

export default Home;
