import {
  Grid,
  Menu,
  TextField,
  MenuItem,
  ListItemText,
  Button,
  experimentalStyled as styled,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setUserInformation } from '../../redux/userInformationSlice';
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FE4F13',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FE4F13',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#282c34',
    },
    '&:hover fieldset': {
      borderColor: '#FE4F13',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FE4F13',
    },
  },
});

const LoginDropdown = ({ anchorEl, handleClose, open }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleTabAndEnter = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  const handleLoginClick = () => {
    axios
      .post(`/users/login`, {
        user_name: username,
        password: password,
      })
      .then((response) => {
        console.log(response);
        let token = response.data.token;
        let decoded = jwt_decode(token);
        console.log(decoded);
        dispatch(setUserInformation(decoded));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClose();
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        onKeyDown={handleKeyDown}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'center' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: 350,
            backgroundColor: '#fff',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.8,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <CssTextField
            variant='outlined'
            label='Username'
            onKeyDown={handleTabAndEnter}
            onClick={(event) => event.stopPropagation()}
            onChange={handleUsernameChange}
            size='small'
            margin='dense'
            sx={{ backgroundColor: 'white' }}
          />
          <CssTextField
            variant='outlined'
            label='Password'
            type='password'
            onKeyDown={handleTabAndEnter}
            onClick={(event) => event.stopPropagation()}
            onChange={handlePasswordChange}
            size='small'
            margin='dense'
            sx={{ backgroundColor: 'white' }}
          />
          <Button
            variant='contained'
            onClick={handleLoginClick}
            sx={{
              backgroundColor: '#FE4F13',
              color: 'white',
              margin: '1rem',
              borderRadius: 1,
              flex: 1,
              fontWeight: 700,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
          >
            Login
          </Button>
        </Grid>
        <MenuItem>
          <ListItemText
            onClick={() => navigate('/userregister')}
            primary='Need an account? Click here to sign up!'
            sx={{
              color: 'black',
              fontFamily: "'Slabo 27px', serif",
              textAlign: 'center',
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default LoginDropdown;
