import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import EmbeddedVideo from '../../appComponents/EmbeddedVideo';

const DynamicSponsors = ({ sponsor }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getContent = axios
      .get('/rotr/sponsors/content', {
        params: { sponsor: sponsor },
      });
    const getHeaderBanner = axios
      .get('/rotr/sponsors/header-banner', {
        params: { sponsor: sponsor },
      });
    const getBanners = axios
      .get('/rotr/sponsors/banners', {
        params: { sponsor: sponsor },
      });

    Promise
      .allSettled([getContent, getHeaderBanner, getBanners])
      .then((res) => {
        setData({
          content: res[0]?.value?.data ?? null,
          headerBanner: res[1]?.value?.data ?? null,
          banners: res[2]?.value?.data ?? null,
        });
      })
      .catch((err) => {
        console.error(err);
      })
  }, [sponsor])

  if (!data) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      {data.headerBanner?.length > 0 && (
        <Grid item container xs={12} md={12} sx={{
          alignContent: 'center',
        }}>
          <img src={data.headerBanner[0].header_banner_url} alt='Sponsor' style={{ width: '100%', height: 'auto' }} />
        </Grid>
      )}

      {data.content?.length > 0 && (
        <>
          <Grid item xs={12} md={6}>
            <EmbeddedVideo src={data.content[0].video_url} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ position: 'relative', height: { xs: null, md: '100%' } }}>
              <Box sx={{
                position: { xs: null, md: 'absolute' },
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <img
                  src={data.content[0].logo_url}
                  alt='Logo'
                  style={{ maxWidth: '100%', marginBottom: 16 }}
                />
                <Typography variant='body1' align='justify' sx={{ width: '100%' }}>
                  {data.content[0].detailed_description}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </>
      )}

      {data.banners?.length > 0 && data.banners.map((banner) => (
        <Grid key={banner.banner_id} item container xs={6} md={3}>
          <a href={banner.banner_link_url} target='_blank' rel='noopener noreferrer'>
            <img
              src={banner.banner_location_url}
              alt='Banner'
              style={{ width: '100%', height: 'auto' }}
            />
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicSponsors;
