import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ItemCard from './Components/ItemCard';

const OutdoorPrizes = () => {
  const [items, setItems] = useState([]);
  const [id, setId] = useState('');
  const [tier, setTier] = useState('');

  const parseUrl = () => {
    const parts = window.location.pathname.split('-');
    if (parts.length > 1) {
      setId(parts[0].substring(1));
      setTier(parts[1][0]);
    }
  };

  useEffect(() => {
    parseUrl();
    axios
      .post(
        '/outdoorprizes',
        {},
        {
          params: {
            id: id,
            tier: tier,
          },
        }
      )
      .then((res) => {
        setItems(res.data);
      });
  }, []);

  return setItems.map((item) => {
    return <ItemCard item={item} />;
  });
};

export default OutdoorPrizes;
