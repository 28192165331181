import React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import RendezvousLogo from '../Assets/rendezvousLogo.png';
import { useNavigate } from 'react-router-dom';

const pages = ['home', 'show highlights', 'attendees', 'exhibitors', 'sponsors', 'venue'];

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (page) => {
    if (page === 'home') {
      navigate('/');
    } else {
      navigate(`/${page.replaceAll(' ', '-')}`);
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position='static'
      sx={{ backgroundColor: '#ffffff', borderBottom: '4px solid #cb351a' }}
    >
      <Box maxWidth='100%' sx={{ p: 0 }}>
        <Toolbar disableGutters sx={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          maxWidth: '1600px',
          m: '0 auto',
          flexDirection: { xs: 'row-reverse', lg: 'row' },
        }}>
          {/* third invisible element, to center logo when screen is small enough */}
          <Box sx={{ display: { xs: 'flex', lg: 'none' }, width: '48px' }} />

          <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{
              color: '#00325a',
              textDecoration: 'none',
              m: 1,
            }}
          >
            <img
              src={RendezvousLogo}
              alt='Rendezvous on the Red'
              style={{ width: 'auto', height: '100%', maxHeight: '60px', display: 'block' }}
            />
          </Typography>

          <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon sx={{ color: '#00325a' }} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => handleNavigate(page)}
                  sx={{
                    color: '#00325a',
                    '&:hover': {
                      backgroundColor: '#cb351a',
                      color: '#ffffff',
                    },
                  }}>
                  <Typography sx={{
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
            {pages.map((page) => (
              <Box key={page} sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Button
                  key={page}
                  onClick={() => handleNavigate(page)}
                  sx={{
                    color: '#00325a',
                    backgroundColor: '#f4f4f4',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#cb351a',
                      color: '#ffffff',
                    },
                    mx: 0.5,
                  }}
                >
                  <Typography variant='h6' sx={{}}>
                    {page}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default Navbar;
