import * as React from 'react';
import { Button, Typography, Link } from '@mui/material';

const ContactUs = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        If you have questions regarding a recent order request, questions about
        a product, or if you experience and issue while using our website,
        please email us and we'll get back with you as soon as possible. We make
        every attempt to respond within 24 hours (Monday - Friday), but this can
        be delayed during peak seasons.
        <br></br>
        <Button
          size='large'
          sx={{
            backgroundColor: '#FE4F13',
            margin: '1rem',
            color: 'white',
            borderRadius: 1,
            flex: 1,
            fontWeight: 700,
            ':hover': { backgroundColor: '#FE4F13' },
          }}
        >
          <Link underline='none' color='inherit' href='/contactus'>
            {'Contact Us'}
          </Link>
        </Button>
      </Typography>
    </>
  );
};
export default ContactUs;
