import React, { useState } from 'react';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Typography, Divider, experimentalStyled as styled, Paper, Grid, Button, TextField } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#282c34',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CssTextField = styled(TextField)({
  '& input': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FE4F13',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#FE4F13',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FE4F13',
    },
  },
});

const CssDatePicker = styled(DatePicker)({
  '& input': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FE4F13',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#FE4F13',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FE4F13',
    },
  },
});

const UserRegister = () => {
  const [date, setDate] = React.useState(dayjs('2022-04-07'));
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegistrationClick = () => {
    axios
      .post(`https://node.sportssouth.dev/users/login`, {
        user_name: username,
        password: password,
        email_address: email,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUsername = (event) => {
    setUsername(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const [value, setValue] = React.useState(null);

  return (
    <>
      <Divider sx={{ '&::before, &::after': { borderColor: '#282c34' } }}>
        <Typography
          variant='overline'
          sx={{
            fontFamily: "'Slabo, 27px', serif",
            fontWeight: 550,
            fontSize: 50,
            color: '#282c34',
          }}
        >
          User Registration
        </Typography>
      </Divider>
      <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
        <Typography
          variant='h5'
          sx={{
            maxWidth: '60vw',
            fontFamily: "'Slabo, 27px', serif",
            color: '#282c34',
            textAlign: 'left',
            paddingBottom: '5rem',
          }}
        >
          Create Account
        </Typography>
      </Grid>
      <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
        <Grid item xs={2} sm={4} md={4}>
          <Item
            sx={{
              borderRadius: 2,
              maxWidth: '60vw',
              boxShadow: '5px 3px 5px #282c34',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssDatePicker
                label='Date of Birth'
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <CssTextField fullWidth label='Email' id='fullWidth' onChange={handleEmail} size='small' margin='normal' />
            <CssTextField fullWidth label='Create Username' id='fullWidth' margin='normal' size='small' onChange={handleUsername} />
            <CssTextField fullWidth id='outlined-textarea' label='Create Password' size='small' onChange={handlePassword} margin='normal' />
            <Button
              size='large'
              onClick={handleRegistrationClick}
              sx={{
                backgroundColor: '#FE4F13',
                margin: '1rem',
                color: 'white',
                borderRadius: 1,
                flex: 1,
                fontWeight: 700,
                ':hover': { backgroundColor: '#FE4F13' },
              }}
            >
              Create Account
            </Button>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default UserRegister;
