import { Grid } from '@mui/material';
import React from 'react';

const HomeFeatured = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Featured Items</h1>
      </Grid>
    </Grid>
  );
};

export default HomeFeatured;
