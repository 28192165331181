import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import Sidebar from '../sidebar/sidebar';
import Home from '../home/home';
import Firearms from '../categories/firearms';
import Ammunition from '../categories/ammunition';
import Optics from '../categories/optics';
import Accessories from '../categories/accessories';
import Gear from '../categories/gear';
import StoreSelect from '../storeSelect/storeSelect';
import AboutUs from '../aboutUs/aboutUs';
import ContactUs from '../contactUs/contactUs';
import UserSettings from '../userSettings/userSettings';
import UserRegister from '../userRegister/userRegister';
import Dealers from '../dealers/dealers';
import Checkout from '../checkout/checkout';
import ItemDetail from '../itemDetail/itemDetail';
import UserOrders from '../userOrders/userOrders';
import FilterSidebar from '../sidebar/filterSidebar';
import SearchResults from '../searchResults/searchResults';

const TSSDesktopRoutes = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route element={<FilterSidebar />}>
          <Route path='/searchresults' element={<SearchResults />} />
        </Route>
        <Route element={<Sidebar />}>
          <Route index element={<Home />} />
          <Route exact path='/home' element={<Home />} />
          <Route path='/firearms' element={<Firearms />} />
          <Route path='/ammunition' element={<Ammunition />} />
          <Route path='/optics' element={<Optics />} />
          <Route path='/accessories' element={<Accessories />} />
          <Route path='/gear' element={<Gear />} />
          <Route path='/storeselect' element={<StoreSelect />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/usersettings' element={<UserSettings />} />
          <Route path='/userregister' element={<UserRegister />} />
          <Route path='/dealers' element={<Dealers />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/itemdetail/:itemid' element={<ItemDetail />} />
          <Route path='/myorders' element={<UserOrders />} />
        </Route>
      </Routes>
    </>
  );
};

export default TSSDesktopRoutes;
