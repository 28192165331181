import React, { useState } from 'react';
import { IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSearchResult } from '../../redux/searchResultSlice';
import AxiosGet from '../../appUtilities/axiosGet';

const SearchBox = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm === '') {
      return;
    }
    AxiosGet(`/products/cloudSearch/${searchTerm}&size=10000`).then((res) => {
      dispatch(setSearchResult(res.data));
      navigate('/searchResults');
    });
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Paper
      component='form'
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', height: 30 }}
    >
      <form onSubmit={handleSubmit} onKeyDown={handleEnterKey}>
        <InputBase
          value={searchTerm}
          onChange={handleChange}
          sx={{ ml: 1, flex: 1, width: 250 }}
          placeholder='Search'
          inputProps={{ 'aria-label': 'search' }}
        />
      </form>
      <IconButton type='submit' sx={{ p: '10px' }} aria-label='search' onClick={handleSubmit}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBox;
