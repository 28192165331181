import { Menu, Grid, Button, TextField, experimentalStyled as styled } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FE4F13',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FE4F13',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#282c34',
    },
    '&:hover fieldset': {
      borderColor: '#FE4F13',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FE4F13',
    },
  },
});

const DealerDropdown = ({ anchorEl, handleClose, open }) => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [dealers, setDealers] = useState([]);

  // const user = useSelector((state) => state.user.value.profileId);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      axios
        .get(`/dealers/zip/${search}`)
        .then((response) => {
          setDealers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const handleDealerSelect = (dealer) => {
  //   axios.put(`/users/${user}/preferredDealer/update`, { dealerId: dealer.id }).then((response) => {
  //     console.log(response);
  //   });
  // };

  const renderDealers = () => {
    return dealers.map((dealer) => {
      return (
        <Grid
          container
          direction='row'
          spacing={3}
          justifyContent='left'
          alignItems='center'
          key={dealer.id}
        >
          <Grid item xs={9}>
            {dealer.name}
          </Grid>
          <Grid item xs={3}>
            <Button
              variant='contained'
              size='small'
              // onClick={() => handleDealerSelect(dealer)}
              sx={{
                backgroundColor: '#FE4F13',
                color: 'white',
                borderRadius: 1,
                flex: 1,
                fontWeight: 700,
                ':hover': { backgroundColor: '#FE4F13' },
              }}
            >
              Select
            </Button>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'center' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: 350,
            backgroundColor: '#fff',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.8,
            '& .MuiAvatar-root': {
              width: 50,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
          <CssTextField
            variant='outlined'
            label='Enter Zip Code'
            onClick={(event) => event.stopPropagation()}
            onChange={handleSearch}
            onKeyDown={handleEnterKey}
            size='small'
            margin='dense'
            sx={{ backgroundColor: 'white' }}
          />
          {renderDealers()}
          <Button
            variant='contained'
            onClick={() => navigate('/dealers')}
            sx={{
              backgroundColor: '#FE4F13',
              color: 'white',
              borderRadius: 1,
              flex: 1,
              fontWeight: 700,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
          >
            View Dealer Map
          </Button>
        </Grid>
      </Menu>
    </>
  );
};

export default DealerDropdown;
