import React from 'react';
import {
  Divider,
  Typography,
  Grid,
  Tab,
  Tabs,
  Box,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import HowItWorks from './cardComponents/howItWorks';
import HowYouPay from './cardComponents/howYouPay';
import TransferFees from './cardComponents/transferFees';
import ShippingTimes from './cardComponents/shippingTimes';
import DealerSignup from './cardComponents/dealerSignup';
import ContactUs from './cardComponents/contactUs';
import PropTypes from 'prop-types';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#FE4F13',
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AboutUs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography
          variant='overline'
          sx={{
            fontFamily: "'Slabo, 27px', serif",
            fontWeight: 550,
            fontSize: 50,
            color: '#282c34',
            textAlign: 'center',
          }}
        >
          <Divider sx={{ '&::before, &::after': { borderColor: '#282c34' } }}>About Us</Divider>
        </Typography>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
          <Typography
            variant='h5'
            sx={{
              fontFamily: "'Slabo, 27px', serif",
              color: '#282c34',
              textAlign: 'left',
              paddingBottom: '2rem',
              maxWidth: '60vw',
            }}
          >
            The Shooting Store is an online catalog of firearms, ammunition, and shooting sports
            accessories. Since launching in 2014, The Shooting Store has been providing Americans an
            easy way to browse and place orders for products that are available for pickup at their
            local licensed dealers (FFL Dealers). All payments, legal paperwork, and verification
            occurs between the user and the local licensed dealer before the products are transfered
            to the user.
          </Typography>
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Tabs
              variant='fullWidth'
              textColor='secondary'
              indicatorColor='secondary'
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              sx={{
                backgroundColor: '#282c34',
                borderRadius: '5px',
                boxShadow: '5px 3px 5px #282c34',
                maxWidth: '60vw',
                minWidth: '60vw',
              }}
            >
              <Tab
                sx={{
                  color: 'white',
                }}
                label='How It Works'
                {...a11yProps(0)}
              />
              <Tab sx={{ color: 'white' }} label='How You Pay' {...a11yProps(1)} />
              <Tab sx={{ color: 'white' }} label='Transfer Fees' {...a11yProps(2)} />
              <Tab sx={{ color: 'white' }} label='Shipping Times' {...a11yProps(3)} />
              <Tab sx={{ color: 'white' }} label='Dealer Signup' {...a11yProps(4)} />
              <Tab sx={{ color: 'white' }} label='Contact Us' {...a11yProps(5)} />
            </Tabs>
          </Grid>
          <Grid item sx={{ maxWidth: '63vw' }}>
            <TabPanel value={value} index={0}>
              <HowItWorks />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HowYouPay />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <TransferFees />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ShippingTimes />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <DealerSignup />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ContactUs />
            </TabPanel>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default AboutUs;
