import React from 'react';
import CheckoutCart from './components/checkoutCart';
import CheckoutInfo from './components/checkoutInfo';
import CheckoutReview from './components/checkoutReview';
import { useState } from 'react';
import { Grid } from '@mui/material';

const Checkout = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    console.log('submit');
  };

  return (
    <Grid container>
      {step === 1 && <CheckoutCart nextStep={nextStep} />}
      {step === 2 && <CheckoutInfo nextStep={nextStep} prevStep={prevStep} />}
      {step === 3 && <CheckoutReview prevStep={prevStep} handleSubmit={handleSubmit} />}
    </Grid>
  );
};

export default Checkout;
