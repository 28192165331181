import { React, useState } from 'react';
import { Badge, Grid, Button } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchBox from './searchBox';
import { useNavigate } from 'react-router-dom';
import DealerDropdown from './dealerDropdown';
import LoginDropdown from './loginDropdown';
import MiniCart from './miniCart';
import { useSelector } from 'react-redux';

const Menu = () => {
  const [dealerAnchorEl, setDealerAnchorEl] = useState(null);
  const handleDealerClick = (event) => {
    setDealerAnchorEl(event.currentTarget);
  };
  const handleDealerClose = () => {
    setDealerAnchorEl(null);
  };

  const dealerOpen = Boolean(dealerAnchorEl);

  const [loginAnchorEl, setLoginAnchorEl] = useState(null);
  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };
  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };
  const loginOpen = Boolean(loginAnchorEl);

  const [miniCartAnchorEl, setMiniCartAnchorEl] = useState(null);
  const handleMiniCartClick = (event) => {
    setMiniCartAnchorEl(event.currentTarget);
  };
  const handleMiniCartClose = () => {
    setMiniCartAnchorEl(null);
  };
  const miniCartOpen = Boolean(miniCartAnchorEl);

  const user = useSelector((state) => state.persistedReducer.userInformation.value);
  const cart = useSelector((state) => state.persistedReducer.shoppingCart.value);
  const navigate = useNavigate();

  const getCartTotal = () => {
    const total = cart.map((item) => item.quantity).reduce((a, b) => a + b, 0);
    return total;
  };

  return (
    <>
      <Grid
        container
        wrap='nowrap'
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'space-between',
          whiteSpace: 'nowrap',
        }}
      >
        <Grid item alignContent='left'>
          <SearchBox />
        </Grid>
        <Grid item>
          <Button
            sx={{
              color: 'white',
              fontFamily: "'Slabo 27px', serif",
              fontSize: 16,
              fontWeight: 600,
              width: '100%',
            }}
            onClick={handleDealerClick}
            id='navDealerButton'
            aria-haspopup='true'
            aria-expanded={dealerOpen ? 'true' : undefined}
          >
            <StoreIcon sx={{ color: '#FE4F13', p: 1, fontSize: 45 }} />
            Select Store
          </Button>
        </Grid>
        {user.userId ? (
          <Grid item>
            <Button
              sx={{
                color: 'white',
                fontFamily: "'Slabo 27px', serif",
                fontSize: 16,
                fontWeight: 600,
              }}
              onClick={() => navigate('/usersettings')}
            >
              <AccountCircleIcon sx={{ color: '#FE4F13', p: 1, fontSize: 45 }} />
              My Account
            </Button>
          </Grid>
        ) : (
          <Grid item>
            <Button
              sx={{
                color: 'white',
                fontFamily: "'Slabo 27px', serif",
                fontSize: 16,
                fontWeight: 600,
              }}
              onClick={handleLoginClick}
              id='navLoginButton'
              aria-haspopup='true'
              aria-expanded={loginOpen ? 'true' : undefined}
            >
              <AccountCircleIcon sx={{ color: '#FE4F13', p: 1, fontSize: 45 }} />
              Log In/Sign Up
            </Button>
          </Grid>
        )}
        {user.userId ? (
          <Grid item>
            <Button
              sx={{
                color: 'white',
                fontFamily: "'Slabo 27px', serif",
                fontSize: 16,
                fontWeight: 600,
              }}
              onClick={() => navigate('/myorders')}
            >
              <PeopleIcon sx={{ color: '#FE4F13', p: 1, fontSize: 45 }} />
              My Orders
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <Button
            sx={{
              color: 'white',
              fontFamily: "'Slabo 27px', serif",
              fontSize: 16,
              fontWeight: 600,
            }}
            onClick={handleMiniCartClick}
            id='miniCartButton'
            aria-haspopup='true'
            aria-expanded={miniCartOpen ? 'true' : undefined}
          >
            <Badge
              badgeContent={getCartTotal()}
              sx={{
                '& .MuiBadge-badge': {
                  color: 'white',
                  fontSize: 16,
                  backgroundColor: '#FE4F13',
                },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <ShoppingCartIcon sx={{ color: '#FE4F13', p: 1, fontSize: 45 }} />
            </Badge>
            My Cart
          </Button>
        </Grid>
      </Grid>
      <DealerDropdown anchorEl={dealerAnchorEl} handleClose={handleDealerClose} open={dealerOpen} />
      <LoginDropdown anchorEl={loginAnchorEl} handleClose={handleLoginClose} open={loginOpen} />
      <MiniCart anchorEl={miniCartAnchorEl} handleClose={handleMiniCartClose} open={miniCartOpen} />
    </>
  );
};

export default Menu;
