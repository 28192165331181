import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import React from 'react';
import { useState } from 'react';

const MobileBottomNav = () => {
  const [value, setValue] = useState(0);
  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label='Cart' />
        <BottomNavigationAction label='Filters' />
        <BottomNavigationAction label='Search' />
      </BottomNavigation>
    </Paper>
  );
};

export default MobileBottomNav;
