import React from 'react';
import { ListItem, Divider, ListItemAvatar, ListItemText, List, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import AccordionCategories from './accordionCategories';

const SidebarMenu = () => {
  return (
    <Box sx={{ backgroundColor: '#282c34', marginTop: 6 }}>
      <List
        sx={{
          fontFamily: "'Slabo 27px', serif",
          fontSize: 18,
          fontWeight: 500,
          color: 'white',
        }}
      >
        <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>
          <ListItem button>
            <ListItemAvatar sx={{ color: '#FE4F13' }}>
              <HomeIcon />
            </ListItemAvatar>
            <ListItemText primary='HOME' />
          </ListItem>
        </Link>
        <Divider sx={{ backgroundColor: '#FE5F13' }} />
        <Link to='/aboutus' style={{ textDecoration: 'none', color: 'white' }}>
          <ListItem button>
            <ListItemAvatar sx={{ color: '#FE4F13' }}>
              <GroupIcon />
            </ListItemAvatar>
            <ListItemText primary='ABOUT US' />
          </ListItem>
        </Link>
        <Divider sx={{ backgroundColor: '#FE5F13' }} />
        <AccordionCategories />
        <Divider sx={{ backgroundColor: '#FE5F13', borderBottomWidth: 1.5 }} />
      </List>
    </Box>
  );
};

export default SidebarMenu;
