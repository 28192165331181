import { Grid } from '@mui/material';
import React from 'react';

const HomeContent = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Home Content</h1>
      </Grid>
    </Grid>
  );
};

export default HomeContent;
