import { Button } from '@mui/material';

//General Button Used Throughout Most Components

const GeneralButton = ({ text, onClick }) => {
  return (
    <>
      <Button
        variant='contained'
        sx={{
          backgroundColor: '#FE4F13',
          color: 'white',
          borderRadius: 1,
          flex: 1,
          fontWeight: 700,
          width: 200,
          whiteSpace: 'nowrap',
          ':hover': { backgroundColor: '#FE4F13' },
        }}
        onClick={onClick}
      >
        {text}
      </Button>
    </>
  );
};

export default GeneralButton;
