import React, { useState } from 'react';
import {
  ListItem,
  Divider,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const categories = [
  { title: 'FIREARMS', link: '/firearms' },
  { title: 'AMMUNITION', link: '/ammunition' },
  { title: 'OPTICS', link: '/optics' },
  { title: 'ACCESSORIES', link: '/accessories' },
  { title: 'GEAR', link: '/gear' },
];

const AccordionCategories = () => {
  const [expanded, setExpanded] = useState(true);
  const handleChange = () => {
    setExpanded(!expanded);
  };

  const renderCategoryList = () => {
    return categories.map((category, index) => (
      <React.Fragment key={category.link}>
        <Link to={category.link} style={{ textDecoration: 'none', color: 'white' }}>
          <ListItem>
            <ListItemText primary={category.title} />
          </ListItem>
        </Link>
        {index !== categories.length - 1 && (
          <Divider sx={{ backgroundColor: '#FE5F13', width: '100%' }} />
        )}
      </React.Fragment>
    ));
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        color: 'white',
        backgroundColor: '#282c34',
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownCircleIcon sx={{ color: '#FE4F13' }} />}
        aria-controls='panel1d-content'
        id='panel1d-header'
        sx={{
          fontFamily: "'Slabo 27px', serif",
          fontSize: 18,
          fontWeight: 500,
          pl: 2,
        }}
        onClick={handleChange}
      >
        CATEGORIES
      </AccordionSummary>
      <Divider sx={{ backgroundColor: '#FE5F13' }} />
      <AccordionDetails sx={{ width: 240, p: 0 }}>
        <Box sx={{ width: '100%' }}>{renderCategoryList()}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCategories;
