import * as React from 'react';
import Typography from '@mui/material/Typography';

const ShippingTimes = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        Shipping time can vary, but handguns will usually arrive 2-3 business
        days after your order is placed. Long guns and accessories typically
        arrive in about 4 business days. Your FFL Dealer will contact you when
        your order request is ready to be completed and picked up. Your order
        status will update once your order has shipped.
      </Typography>
    </>
  );
};
export default ShippingTimes;
