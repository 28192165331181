import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, Divider, Box, Paper, Grid } from '@mui/material';
import {
  addToCart,
  decrementItemQuantity,
  incrementItemQuantity,
} from '../redux/shoppingCartSlice';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { setCurrentItem } from '../redux/itemDetailSlice';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const SearchResults = () => {
  const cart = useSelector((state) => state.persistedReducer.shoppingCart.value);
  const searchResults = useSelector((state) => state.persistedReducer.searchResult.value);
  const itemDetail = useSelector((state) => state.persistedReducer.itemDetail.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderSearchResults = () => {
    return searchResults.hits.hit.map((product) => {
      const addToCartButton = () => {
        return (
          <Button
            size='small'
            sx={{
              backgroundColor: '#FE4F13',
              color: 'white',
              borderRadius: 1,
              flex: 1,
              fontWeight: 700,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
            onClick={() =>
              dispatch(
                addToCart({
                  id: product.id,
                  name: product.fields.model,
                  price: '199',
                  image: `https://media.server.theshootingwarehouse.com/small/${product.fields.itemno}.jpg`,
                })
              )
            }
          >
            <AddShoppingCartIcon />
          </Button>
        );
      };
      const addOrRemoveButton = () => {
        return (
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item xs>
              <Button
                size='small'
                sx={{
                  backgroundColor: '#FE4F13',
                  color: 'white',
                  borderRadius: 1,
                  maxWidth: '30px',
                  maxHeight: '30px',
                  minWidth: '30px',
                  minHeight: '30px',
                  margin: '-30px',
                  fontWeight: 400,
                  ':hover': { backgroundColor: '#FE4F13' },
                }}
                onClick={() => dispatch(decrementItemQuantity(product.id))}
              >
                <RemoveIcon fontSize='inherit' />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant='body'
                sx={{
                  fontFamily: "'Slabo, 27px', serif",
                  color: '#282c34',
                  margin: '-10px',
                }}
              >
                {cart.find((item) => item.id === product.id)
                  ? cart.find((item) => item.id === product.id).quantity
                  : 0}
              </Typography>
            </Grid>
            <Grid item xs>
              <Button
                size='small'
                sx={{
                  backgroundColor: '#FE4F13',
                  color: 'white',
                  margin: '-30px',
                  maxWidth: '30px',
                  maxHeight: '30px',
                  minWidth: '30px',
                  minHeight: '30px',
                  borderRadius: 1,
                  fontWeight: 400,
                  ':hover': { backgroundColor: '#FE4F13' },
                }}
                onClick={() => dispatch(incrementItemQuantity(product.id))}
              >
                <AddIcon fontSize='inherit' />
              </Button>
            </Grid>
          </Grid>
        );
      };
      return (
        <Grid
          item
          sx={{
            padding: '10px',
            paddingBottom: '20px',
            height: '40%',
          }}
          xs={4}
          key={product.id}
        >
          <Item
            sx={{
              height: '95%',
              borderRadius: '5px',
              boxShadow: '5px 3px 5px #282c34',
              '&:not(:last-child)': {
                borderBottom: 0,
              },
              '&:before': {
                display: 'none',
              },
            }}
          >
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              height='100%'
            >
              <Grid item xs={12}>
                <Divider sx={{ '&::before, &::after': { borderColor: '#282c34' } }}>
                  <Typography
                    variant='h6'
                    sx={{
                      fontFamily: "'Slabo, 27px', serif",
                      color: '#282c34',
                    }}
                  >
                    {product.fields.mfgno}
                    &nbsp;
                    {product.fields.model}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <img
                    src={`https://media.server.theshootingwarehouse.com/small/${product.fields.itemno}.jpg`}
                    alt='product'
                    style={{
                      maxWidth: '100%',
                      height: 80,
                      objectFit: 'contain',
                    }}
                  />
                </Box>
                <Divider sx={{ borderColor: '#282c34' }} />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body'
                  sx={{
                    fontFamily: "'Slabo, 27px', serif",
                    color: '#282c34',
                  }}
                >
                  Item #
                </Typography>
                <Divider sx={{ borderColor: '#282c34' }} />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body'
                  sx={{
                    fontFamily: "'Slabo, 27px', serif",
                    color: '#282c34',
                  }}
                >
                  Skew
                </Typography>
                <Divider sx={{ borderColor: '#282c34' }} />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body'
                  sx={{
                    fontFamily: "'Slabo, 27px', serif",
                    color: '#282c34',
                  }}
                >
                  Data
                </Typography>
                <Divider sx={{ borderColor: '#282c34' }} />
              </Grid>
              <Grid item xs={4}>
                <Button
                  size='small'
                  onClick={() => {
                    dispatch(setCurrentItem(product));
                    navigate(`/itemdetail/${product.fields.itemno}`);
                  }}
                  sx={{
                    backgroundColor: '#FE4F13',
                    color: 'white',
                    borderRadius: 1,
                    flex: 1,
                    fontWeight: 700,
                    ':hover': { backgroundColor: '#FE4F13' },
                  }}
                >
                  {' '}
                  Details{' '}
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant='body'
                  sx={{
                    fontFamily: "'Slabo, 27px', serif",
                    color: '#282c34',
                  }}
                >
                  $1,199.99
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {cart.find((item) => item.id === product.id)
                  ? addOrRemoveButton(product.id)
                  : addToCartButton(product.id)}
              </Grid>
            </Grid>
          </Item>
        </Grid>
      );
    });
  };

  return (
    <>
      <Box
        display='flex'
        flex='1'
        justifyContent='center'
        alignItems='center'
        sx={{
          backgroundColor: '#282c34',
          height: '88vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          padding: 1,
        }}
      >
        <Grid container height='100%'>
          {renderSearchResults()}
        </Grid>
      </Box>
    </>
  );
};

export default SearchResults;
