import { Grid } from '@mui/material';
import React from 'react';
import GeneralButton from '../../appComponents/generalButton';

const CheckoutInfo = ({ nextStep, prevStep }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Info Step</h1>
      </Grid>
      <Grid item xs={6}>
        <GeneralButton onClick={prevStep} text='Back' />
      </Grid>
      <Grid item xs={6}>
        <GeneralButton onClick={nextStep} text='Next' />
      </Grid>
    </Grid>
  );
};

export default CheckoutInfo;
