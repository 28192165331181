import React, { useState } from 'react';
import { Button, MenuItem, TextField, Grid, Paper, Typography, Divider, experimentalStyled as styled } from '@mui/material';
import axios from 'axios';

const subjects = [
  {
    value: 'Order Request',
    label: 'Order Request',
  },
  {
    value: 'Product Question',
    label: 'Product Question',
  },
  {
    value: 'Website Issue',
    label: 'Website Issue',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CssTextField = styled(TextField)({
  '& input': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#FE4F13',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#FE4F13',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#FE4F13',
    },
  },
});

const ContactUs = () => {
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailSent, setEmailSent] = useState();

  const handleSubmit = () => {
    axios
      .post('https://node.sportssouth.dev/email/tsshelp', {
        emailAddress: email,
        firstName: firstName,
        lastName: lastName,
        subject: subject,
        message: message,
      })
      .then(
        (response) => {
          console.log(response);
          setEmailSent(true);
        },
        (error) => {
          console.log(error);
          setEmailSent(false);
        }
      );
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const emailChecker = () => {
    if (emailSent === true) {
      return (
        <Typography
          variant='h6'
          sx={{ color: '#FE4F13', textAlign: 'center', marginTop: '1rem' }}
        >
          Email Sent!
        </Typography>
      );
    } else if (emailSent === false) {
      return (
        <>
          <Typography
            variant='h6'
            sx={{ color: '#FE4F13', textAlign: 'center', marginTop: '1rem' }}
          >
            Email Failed to Send
          </Typography>
          <Button
            size='large'
            onClick={handleSubmit}
            sx={{
              backgroundColor: '#FE4F13',
              margin: '1rem',
              color: 'white',
              borderRadius: 1,
              flex: 1,
              fontWeight: 700,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
          >
            Send Message
          </Button>
        </>
      );
    } else {
      return (
        <Button
          size='large'
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#FE4F13',
            margin: '1rem',
            color: 'white',
            borderRadius: 1,
            flex: 1,
            fontWeight: 700,
            ':hover': { backgroundColor: '#FE4F13' },
          }}
        >
          Send Message
        </Button>
      );
    }
  };

  return (
    <>
      <Divider sx={{ '&::before, &::after': { borderColor: '#282c34' } }}>
        <Typography
          variant='overline'
          sx={{
            fontFamily: "'Slabo, 27px', serif",
            fontWeight: 550,
            fontSize: 50,
            color: '#282c34',
          }}
        >
          Contact Us
        </Typography>
      </Divider>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          variant='h5'
          sx={{
            fontFamily: "'Slabo, 27px', serif",
            color: '#282c34',
            textAlign: 'left',
            paddingBottom: '5rem',
            maxWidth: '60vw',
          }}
        >
          If you have questions regarding a recent order request, questions
          about a product, or if you experience and issue while using our
          website, please email us and we'll get back with you as soon as
          possible. We make every attempt to respond within 24 hours (Monday -
          Friday), but this can be delayed during peak seasons.
        </Typography>
      </Grid>
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        alignItems='center'
      >
        <Grid item xs={2} sm={4} md={4}>
          <Item
            sx={{
              backgroundColor: '#282c34',
              borderRadius: 2,
              maxWidth: '60vw',
              boxShadow: '5px 3px 5px #282c34',
            }}
          >
            <CssTextField
              id='firstName'
              label='First Name'
              variant='outlined'
              onChange={handleFirstNameChange}
              sx={{ margin: '1rem' }}
              size='small'
            />
            <CssTextField
              id='outlined-basic'
              label='Last Name'
              variant='outlined'
              size='small'
              onChange={handleLastNameChange}
              sx={{ color: '#FE4F13', margin: '1rem' }}
            />
            <CssTextField
              fullWidth
              label='Email'
              id='fullWidth'
              onChange={handleEmailChange}
              size='small'
              margin='normal'
            />
            <CssTextField
              fullWidth
              label='Subject'
              id='fullWidth'
              margin='normal'
              size='small'
              select
              value={subject}
              onChange={handleSubjectChange}
            >
              {subjects.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
            <CssTextField
              fullWidth
              id='outlined-textarea'
              label='Message'
              size='small'
              onChange={handleMessageChange}
              placeholder='Let us know how we can help!'
              multiline
              margin='normal'
            />
            {emailChecker()}
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
