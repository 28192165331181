import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Paper, Divider } from '@mui/material';

const Accessories = () => {
  const accessories = useSelector((state) =>
    state.persistedReducer.categories.value.filter(
      (category) => category.department === 'Accessories'
    )
  );

  const uniqueCategories = () => {
    const uniqueCategoryGroups = [];
    accessories.forEach((category) => {
      if (!uniqueCategoryGroups.includes(category.categoryGroup)) {
        uniqueCategoryGroups.push(category.categoryGroup);
        uniqueCategoryGroups.sort();
      }
    });
    return uniqueCategoryGroups;
  };

  return (
    <>
      <Grid container direction='row' alignItems='center' justifyContent='stretch' spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant='overline'
            sx={{
              fontFamily: "'Slabo, 27px', serif",
              fontWeight: 550,
              fontSize: 50,
              color: '#282c34',
              textAlign: 'center',
            }}
          >
            <Divider sx={{ '&::before, &::after': { borderColor: '#282c34' } }}>
              Accessories
            </Divider>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        alignItems='flex-start'
        justifyContent='flex-start'
        spacing={1}
      >
        {uniqueCategories().map((category) => (
          <Grid item xs={12} sm={12} md={3} lg={3} textAlign='center'>
            <Paper
              textAlign='center'
              elevation={5}
              sx={{ padding: 2, margin: 1, backgroundColor: '#282c34', color: 'white' }}
            >
              <Typography variant='h6' sx={{ borderBottom: 2, borderColor: '#FE5F13' }}>
                {category}
              </Typography>
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='center'
                spacing={1}
                sx={{ marginTop: 1 }}
              >
                {accessories.map((accessory) => {
                  if (accessory.categoryGroup === category) {
                    return (
                      <Grid item xs={12}>
                        <Typography variant='body2'>{accessory.category}</Typography>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Accessories;
