import { createSlice } from '@reduxjs/toolkit';

export const itemDetailSlice = createSlice({
  name: 'itemDetail',
  initialState: {
    value: [],
  },
  reducers: {
    setCurrentItem: (state, action) => {
      state.value = [];
      state.value.push(action.payload);
    },
  },
});

export const { setCurrentItem } = itemDetailSlice.actions;
export default itemDetailSlice.reducer;
