import { createSlice } from '@reduxjs/toolkit';

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState: {
    value: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const item = state.value.find((item) => item.id === action.payload.id);
      if (item) {
        item.quantity++;
      } else {
        state.value.push({ ...action.payload, quantity: 1 });
      }
    },
    incrementItemQuantity: (state, action) => {
      const item = state.value.find((item) => item.id === action.payload);
      item.quantity++;
    },
    decrementItemQuantity: (state, action) => {
      const item = state.value.find((item) => item.id === action.payload);
      if (item.quantity > 1) {
        item.quantity--;
      } else {
        state.value = state.value.filter((item) => item.id !== action.payload);
      }
    },
    removeFromCart: (state, action) => {
      const item = state.value.filter((item) => item.id !== action.payload);
      state.value = item;
    },
    clearCart: (state) => {
      state.value = [];
    },
  },
});

export const { addToCart, incrementItemQuantity, decrementItemQuantity, removeFromCart, clearCart } = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
