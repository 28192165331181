import { useEffect, useState } from 'react';
import DynamicSponsors from './DynamicSponsors';
import DefaultSponsors from './DefaultSponsors';
import { useLocation } from 'react-router-dom';

const Sponsors = () => {
  const [currentSponsor, setCurrentSponsor] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Get the current URL
    const currentUrl = location.pathname;

    // Extract the current sponsor from the URL
    const urlParts = currentUrl.split('/');
    const currentSponsorIndex = urlParts.indexOf('sponsors') + 1;

    // Check if the current sponsor exists
    if (currentSponsorIndex < urlParts.length) {
      const currentSponsor = urlParts[currentSponsorIndex];
      setCurrentSponsor(currentSponsor);
    } else {
      setCurrentSponsor('');
    }
  }, [location.pathname]);

  // Return nothing if initial useEffect has not yet completed
  if (currentSponsor === null) {
    return null;
  }

  return (
    currentSponsor ? (
      <DynamicSponsors sponsor={currentSponsor} />
    ) : (
      <DefaultSponsors />
    )
  );
};

export default Sponsors;
