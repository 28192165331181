import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Grid,
  Typography,
  Paper,
  experimentalStyled as styled,
} from '@mui/material';
import {
  addToCart,
  decrementItemQuantity,
  incrementItemQuantity,
} from '../redux/shoppingCartSlice';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const ItemDetail = () => {
  const itemDetail = useSelector((state) => state.persistedReducer.itemDetail.value);
  const cart = useSelector((state) => state.persistedReducer.shoppingCart.value);
  const dispatch = useDispatch();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#282c34' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const addToCartButton = () => {
    return (
      <Button
        size='small'
        sx={{
          backgroundColor: '#FE4F13',
          color: 'white',
          borderRadius: 1,
          flex: 1,
          fontWeight: 700,
          ':hover': { backgroundColor: '#FE4F13' },
        }}
        onClick={() =>
          dispatch(
            addToCart({
              id: itemDetail[0].id,
              name: itemDetail[0].fields.model,
              price: '199',
              image: `https://media.server.theshootingwarehouse.com/thumbnail/${itemDetail[0].fields.itemno}.jpg`,
            })
          )
        }
      >
        <AddShoppingCartIcon />
      </Button>
    );
  };
  const addOrRemoveButton = () => {
    return (
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item xs>
          <Button
            size='small'
            sx={{
              backgroundColor: '#FE4F13',
              color: 'white',
              borderRadius: 1,
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
              fontWeight: 400,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
            onClick={() => dispatch(decrementItemQuantity(itemDetail[0].id))}
          >
            <RemoveIcon fontSize='inherit' />
          </Button>
        </Grid>
        <Grid item xs>
          <Typography>
            {cart.find((item) => item.id === itemDetail[0].id)
              ? cart.find((item) => item.id === itemDetail[0].id).quantity
              : 0}
          </Typography>
        </Grid>
        <Grid item xs>
          <Button
            size='small'
            sx={{
              backgroundColor: '#FE4F13',
              color: 'white',
              maxWidth: '30px',
              maxHeight: '30px',
              minWidth: '30px',
              minHeight: '30px',
              borderRadius: 1,
              fontWeight: 400,
              ':hover': { backgroundColor: '#FE4F13' },
            }}
            onClick={() => dispatch(incrementItemQuantity(itemDetail[0].id))}
          >
            <AddIcon fontSize='inherit' />
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Item
        sx={{
          borderRadius: 2,
          border: 3,
          borderColor: '#FE4F13',
          borderStyle: 'ridge',
          boxShadow: '5px 3px 5px #282c34',
        }}
      >
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item xs={6}>
            <img
              alt='ProductImage'
              src={`https://media.server.theshootingwarehouse.com/small/${itemDetail[0].fields.itemno}.jpg`}
            ></img>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant='h5'
              sx={{
                fontFamily: "'Slabo, 27px', serif",
                color: '#282c34',
                textAlign: 'left',
                paddingBottom: '2rem',
                maxWidth: '60vw',
                lineHeight: '200%',
              }}
            >
              {itemDetail[0].id} <br></br>
              {itemDetail[0].fields.mfgno} <br></br>
              {itemDetail[0].fields.model} <br></br>
              {cart.find((item) => item.id === itemDetail[0].id)
                ? addOrRemoveButton(itemDetail[0].id)
                : addToCartButton(itemDetail[0].id)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{
                fontFamily: "'Slabo, 27px', serif",
                color: '#282c34',
                textAlign: 'left',
                paddingBottom: '2rem',
                maxWidth: '60vw',
                lineHeight: '200%',
              }}
            >
              <Divider></Divider>
              Placeholder 1 <br></br>
              <Divider></Divider>
              Placeholder 2 <br></br>
              <Divider></Divider>
              Placeholder 3 <br></br>
              <Divider></Divider>
              Placeholder 4 <br></br>
              <Divider></Divider>
              Placeholder 5 <br></br>
              <Divider></Divider>
              Placeholder 6 <br></br>
              <Divider></Divider>
              Placeholder 7 <br></br>
              <Divider></Divider>
              Placeholder 8 <br></br>
              <Divider></Divider>
              Placeholder 9 <br></br>
              <Divider></Divider>
              Placeholder 10 <br></br>
              <Divider></Divider>
            </Typography>
          </Grid>
        </Grid>
      </Item>
    </>
  );
};

export default ItemDetail;
