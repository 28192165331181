import React from 'react';

const ItemCard = ({ item }) => {
  return (
    <div className='item-card'>
      <img src={item.thumb_image_url} alt={item.name} />
      <h3>{item.title}</h3>
      <p>{item.color}</p>
      <p>{item.price}</p>
    </div>
  );
};

export default ItemCard;
