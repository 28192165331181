import * as React from 'react';
import Typography from '@mui/material/Typography';

const HowItWorks = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        Just create an account and browse our website for the products you are
        interested in. Select an FFL Dealer in your area to view their prices
        and add items to your cart. Once you place your online order request,
        we'll ship it to the FFL Dealer you chose and they'll contact you when
        your order is ready to be completed and picked up.
      </Typography>
    </>
  );
};
export default HowItWorks;
