import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../RendezvousOnTheRed/Components/Navbar';
import Home from '../RendezvousOnTheRed/Components/Home';
import Sponsors from '../RendezvousOnTheRed/Components/Sponsors';
import Attendees from '../RendezvousOnTheRed/Components/Attendees';
import Exhibitors from '../RendezvousOnTheRed/Components/Exhibitors';
import ShowHighlights from '../RendezvousOnTheRed/Components/ShowHighlights';
import Venue from '../RendezvousOnTheRed/Components/Venue';
import Footer from '../RendezvousOnTheRed/Components/Footer';
import { Box } from '@mui/material';
import axios from 'axios';
import ARegistration from '../RendezvousOnTheRed/Components/ARegistration';
import ERegistration from '../RendezvousOnTheRed/Components/ERegistration';

const RendezvousRoutes = () => {
  const [globalStyling, setGlobalStyling] = useState(null);

  useEffect(() => {
    document.title = 'Rendezvous on the Red';
    axios
      .get('/rotr/style/content')
      .then((res) => {
        setGlobalStyling(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (globalStyling && globalStyling.length > 0) {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = globalStyling[0].content;

      document.head.appendChild(styleElement);

      return () => {
        document.head.removeChild(styleElement);
      };
    }
  }, [globalStyling])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Navbar />
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: '1600px',
          margin: '0 auto',
        }}
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path='/show-highlights' element={<ShowHighlights />} />
          <Route path='/attendees' element={<Attendees />} />
          <Route path='/exhibitors' element={<Exhibitors />} />
          <Route path='/sponsors/*' element={<Sponsors />} />
          <Route path='/venue' element={<Venue />} />
          <Route path='/a-registration' element={<ARegistration />} />
          <Route path='/e-registration' element={<ERegistration />} />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
};

export default RendezvousRoutes;
