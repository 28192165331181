import {
  CssBaseline,
  Toolbar,
  Drawer,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
} from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import BetaTSSLogo from '../assets/BetaTSSLogo.png';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FilterBox from './components/filterBox';
import AccordionCategories from './components/accordionCategories';

const FilterSidebar = () => {
  const searchResults = useSelector((state) => state.persistedReducer.searchResult.value);

  return (
    <Box className='overall box' sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        PaperProps={{
          elevation: 12,
        }}
        anchor='left'
        sx={{
          width: 240,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            position: 'fixed',
            backgroundColor: '#282c34',
            width: 240,
            height: '100vh',
          },
          flexShrink: 0,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: '#282c34',
            width: '240px',
            height: '12%',
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          <img
            src={BetaTSSLogo}
            alt='TSS Logo'
            style={{
              width: '100%',
              objectFit: 'contain',
              height: '100%',
              marginTop: 36,
            }}
          />
        </Toolbar>
        <Box sx={{ backgroundColor: '#282c34', marginTop: 6 }}>
          <List
            sx={{
              fontFamily: "'Slabo 27px', serif",
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            <Link to='/' style={{ textDecoration: 'none', color: 'white' }}>
              <ListItem>
                <ListItemAvatar sx={{ color: '#FE4F13' }}>
                  <HomeIcon />
                </ListItemAvatar>
                <ListItemText primary='HOME' disableTypography />
              </ListItem>
            </Link>
            <Divider sx={{ backgroundColor: '#FE5F13' }} variant='fullWidth' />
            <AccordionCategories />
            <FilterBox detail='model' />
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 6, backgroundColor: '#F5F5F5', paddingTop: 11 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default FilterSidebar;
