import * as React from 'react';
import Typography from '@mui/material/Typography';

const HowYouPay = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        You will be required to provide a valid credit card along with your
        signature for verification purposes during the order request checkout
        process. You will pay the full balance of your order directly to the FFL
        Dealer you chose when you arrive to complete your order. If you do not
        complete your order with your FFL Dealer, we reserve the right to charge
        a restocking fee up to 25% of the order total.
      </Typography>
    </>
  );
};
export default HowYouPay;
