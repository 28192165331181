import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Footer = () => {
  return (
    <AppBar position='static' sx={{ backgroundColor: '#ffffff' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters variant='dense' sx={{ justifyContent: 'space-between' }}>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon sx={{ color: '#00325a' }} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              © {new Date().getFullYear()} SPORTS SOUTH, LLC
            </Menu>
          </Box> */}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex', //{ xs: 'none', md: 'flex' },
            }}
          >
            <Typography variant='subtitle1' sx={{ color: '#00325a', whiteSpace: 'pre' }}>
              © {new Date().getFullYear()} SPORTS SOUTH, LLC
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Footer;
