import * as React from 'react';
import Typography from '@mui/material/Typography';

const DealerSignup = () => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          fontFamily: "'Slabo, 27px', serif",
          color: '#181A1D',
          padding: '2rem',
          textAlign: 'left',
          boxShadow: '5px 3px 5px #282c34',
          borderRadius: '8px',
          border: '#282c34',
          borderStyle: 'ridge',
          marginTop: '-1.5rem',
        }}
      >
        If you're an FFL dealer in the United States and you have an
        industry-related, 'brick and mortar' retail storefront, pawn shop, or
        shooting range, send us an email and we'll provide you with all of the
        information you'll need to get listed. To qualify, your business must be
        open to the public with published business hours, have permanent signage
        and parking areas, and cannot be attached to your place of residence.
      </Typography>
    </>
  );
};
export default DealerSignup;
