import { Grid } from '@mui/material';
import React from 'react';
import GeneralButton from '../../appComponents/generalButton';

const CheckoutReview = ({ prevStep, handleSubmit }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Review Step</h1>
      </Grid>
      <Grid item xs={6}>
        <GeneralButton onClick={prevStep} text='Back' />
      </Grid>
      <Grid item xs={6}>
        <GeneralButton onClick={handleSubmit} text='Submit Order' />
      </Grid>
    </Grid>
  );
};

export default CheckoutReview;
