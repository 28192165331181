import { Grid } from '@mui/material';
import React from 'react';
import GeneralButton from '../../appComponents/generalButton';

const CheckoutCart = ({ nextStep }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Cart Step</h1>
      </Grid>
      <Grid item xs={12}>
        <GeneralButton onClick={nextStep} text='Next' />
      </Grid>
    </Grid>
  );
};

export default CheckoutCart;
