import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import Menu from './components/menu';

const Navbar = () => {
  return (
    <AppBar position='fixed' sx={{ backgroundColor: '#282c34', width: 'calc(100% - 240px)', marginLeft: '240px' }}>
      <Toolbar>
        <Menu />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
