import { CssBaseline, Toolbar, Drawer, Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenu from './components/sidebarMenu';
import BetaTSSLogo from '../assets/BetaTSSLogo.png';

const Sidebar = () => {
  return (
    <Box className='overall box' sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        PaperProps={{
          elevation: 12,
        }}
        anchor='left'
        sx={{
          width: 240,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            position: 'fixed',
            backgroundColor: '#282c34',
            width: 240,
            height: '100vh',
          },
          flexShrink: 0,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#282c34',
            width: '100%',
            height: '12%',
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        >
          <img
            src={BetaTSSLogo}
            alt='TSS Logo'
            style={{
              width: '80%',
              objectFit: 'contain',
              maxHeight: '100%',
            }}
          />
        </Toolbar>
        <SidebarMenu />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 6, backgroundColor: '#F5F5F5', paddingTop: 11 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Sidebar;
